import React from 'react';

import { Spinner } from '../../../../common/components/Spinner';
import { PortCalls } from '../../../../store/apis/ais-api';
import { SelectedEvent } from '../../slice';
import { PortCallItem } from './PortCallItem';

type Props = {
  portCalls?: PortCalls[];
  isFetching: boolean;
  selectedEvent: SelectedEvent;
};

export const PortCallList: React.FC<Props> = ({ portCalls, isFetching, selectedEvent }) => {
  return (
    <div className="AnomalyList">
      {isFetching ? (
        <div className="flex items-center justify-center h-full">
          <Spinner className="my-10 h-6 w-6" />
        </div>
      ) : (
        portCalls?.map((portCall) => {
          const selected = portCall.arrival_time === selectedEvent?.id && selectedEvent?.type === 'anomaly';
          return <PortCallItem key={portCall.arrival_time} portCall={portCall} selected={selected} />;
        })
      )}
    </div>
  );
};
