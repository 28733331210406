import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useGetFilteredEvents } from '../../common/hooks';
import { getEventOptionValueFromEventType, getEventTypeFromEventOptionValue } from '../../common/utils';
import { Port, PortCalls, useGetPortEventsPortCallsQuery, useGetVesselPositionsQuery } from '../../store/apis/ais-api';
import { Flagcodes, Shipdata, useGetShipdataQuery } from '../../store/apis/ihs-vessel-data-api';
import { useGetPlacesQuery } from '../../store/apis/port-api';
import { Anomalies, Casualties, Drydockings } from '../../store/apis/vessel-event-api';
import { EventOptionValue } from '../event-filters/slice';
import { setDates, setSelectedTimeRangeOption, VesselMapDateSelectState } from '../vessel-map-date-select/slice';
import { VesselMapDateSelectUrlParams } from '../vessel-map-date-select/utils';
import { setFiltersForSingleVessel, setVesselDetailsState, SingleVesselFilterArg } from './slice';
import { getPositionDatetimesFromEventDatetimes } from './utils';

export type DetailedShipData = Shipdata & {
  flagcodes: Flagcodes;
};

export const useGetDetailedShipdataQuery = (imo: number | string) => {
  const shipDataQuery = useGetShipdataQuery({
    lrimoshipno: `eq.${imo}`,
    select:
      'lrimoshipno,shipname,shiptypelevel4,registeredowner,shipmanager,yearofbuild,classificationsociety,flagname,flagcodes(iso2),statcode5,maritimemobileserviceidentitymmsinumber,deathdate,shipstatus',
  });

  const query = useMemo(
    () => ({
      currentData: shipDataQuery.currentData?.length ? (shipDataQuery.currentData[0] as DetailedShipData) : undefined,
      isFetching: shipDataQuery.isFetching,
    }),
    [shipDataQuery.currentData, shipDataQuery.isFetching]
  );

  return query;
};

export const useSetupFiltersForVesselDetailsView = (imo: number, eventType?: EventOptionValue) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const singleVesselFilterArg: SingleVesselFilterArg = { imo };

    if (eventType) {
      const type = getEventTypeFromEventOptionValue(eventType as EventOptionValue);
      singleVesselFilterArg.type = type;
    }

    dispatch(setFiltersForSingleVessel(singleVesselFilterArg));
  }, [dispatch, eventType, imo]);
};

export const useDatesFromLastPositionConditionally = (imo: number, eventType?: EventOptionValue) => {
  const dispatch = useAppDispatch();
  const latestPositionQuery = useGetLatestVesselPositionQuery(imo);
  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(imo);
  useEffect(() => {
    if (eventType) {
      // Event is selected, map will use event start & end date
      return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params: Partial<VesselMapDateSelectUrlParams> = Object.fromEntries(urlSearchParams.entries());
    if (params.time || params.from || params.to) {
      // Dates defined in the url, map will use those
      return;
    }

    if (positionsQuery.isFetching || positionsQuery.currentData?.results?.[0]?.positions?.length) {
      // Positions available for current dates, no need to use last position date
      return;
    }

    const timestamp = latestPositionQuery.data?.results?.[0]?.positions?.[0]?.timestamp;
    if (!timestamp) {
      return;
    }
    const date = dayjs.utc(timestamp).format('YYYY-MM-DD');
    dispatch(setDates({ startDate: date, endDate: date }));
  }, [
    dispatch,
    eventType,
    imo,
    latestPositionQuery.data,
    positionsQuery.currentData?.results,
    positionsQuery.isFetching,
  ]);
};

export const useGetSelectedEvent = () => {
  const selectedEvent = useAppSelector((state) => state.eventFeed.selectedEvent);
  const isDevApi = useAppSelector((state) => state.common.useDevAisApi!);
  const dateState = useAppSelector((state) => state.vesselMapDateSelect);
  const { anomalies, casualties, drydockings } = useGetFilteredEvents();
  let { imo } = useParams<{ imo: string }>() as { imo: string };
  const portData = useGetPortEventsPortCallsQuery(
    { imo: parseInt(imo) || undefined, from: dateState.startDate, to: dateState.endDate, limit: 100 },
    { skip: !imo || !isDevApi }
  );

  let selectedAnomaly: Anomalies | null = null;
  if (anomalies.currentData && selectedEvent?.type === 'anomaly') {
    selectedAnomaly = (anomalies.currentData as Anomalies[]).find((event) => event.id === selectedEvent.id) || null;
  }

  let selectedCasualty: Casualties | null = null;
  if (casualties.currentData && selectedEvent?.type === 'casualty') {
    selectedCasualty = (casualties.currentData as Casualties[]).find((event) => event.id === selectedEvent.id) || null;
  }

  let selectedDrydock: Drydockings | null = null;
  if (drydockings.currentData && selectedEvent?.type === 'dry-dock') {
    selectedDrydock = (drydockings.currentData as Drydockings[]).find((event) => event.id === selectedEvent.id) || null;
  }

  let selectedPortCall: PortCalls | null = null;
  if (portData.currentData && selectedEvent?.type === 'port-calls') {
    selectedPortCall = portData.currentData.results?.find((event) => event.arrival_time === selectedEvent.id) || null;
  }

  return {
    anomaly: selectedAnomaly,
    casualty: selectedCasualty,
    dryDock: selectedDrydock,
    portCall: selectedPortCall,
  };
};

export const useUpdateDatesWhenSelectedEventChanges = () => {
  const dispatch = useAppDispatch();
  const { anomaly, casualty, dryDock, portCall } = useGetSelectedEvent();

  useEffect(() => {
    let dates: {
      startDate: VesselMapDateSelectState['startDate'];
      endDate: VesselMapDateSelectState['endDate'];
    } = {
      startDate: undefined,
      endDate: undefined,
    };

    if (anomaly) {
      dates = getPositionDatetimesFromEventDatetimes(anomaly.start_datetime as string, anomaly.end_datetime);
    }
    if (casualty) {
      const casualtyDate = dayjs.utc(casualty.casualty_date).format('YYYY-MM-DD');
      dates = {
        startDate: casualtyDate,
        endDate: casualtyDate,
      };
    }
    if (dryDock) {
      dates = getPositionDatetimesFromEventDatetimes(dryDock.start_datetime as string, dryDock.end_datetime);
    }
    if (portCall) {
      dates = getPositionDatetimesFromEventDatetimes(portCall.arrival_time as string, portCall.departure_time);
    }

    if (dates.startDate && dates.endDate) {
      dispatch(setSelectedTimeRangeOption(null));
      dispatch(setDates(dates));
    }
  }, [anomaly, casualty, dispatch, dryDock, portCall]);
};

export const useUpdateUrlWhenSelectedEventChanges = (imo: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedEvent = useAppSelector((state) => state.eventFeed.selectedEvent);

  useEffect(() => {
    const vesselImoUrl = `/vessel/${imo}`;
    if (!selectedEvent) {
      if (location.pathname !== vesselImoUrl) {
        navigate(vesselImoUrl, { replace: true });
      }
      return;
    }

    const type = getEventOptionValueFromEventType(selectedEvent.type);
    const url = `/vessel/${imo}/${type}/${selectedEvent.id}`;
    if (url !== location.pathname) {
      navigate(url, { replace: true });
    }
  }, [imo, location, navigate, selectedEvent]);
};

export const useGetSelectedPosition = () => {
  const { selectedImo } = useAppSelector((state) => state.eventFilters);
  const { selectedPositionTimestamp } = useAppSelector((state) => state.vesselMap);
  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(selectedImo);
  const vesselPosition = positionsQuery.currentData?.results?.length ? positionsQuery.currentData.results[0] : null;
  const selectedPosition = selectedPositionTimestamp
    ? vesselPosition?.positions?.find((position) => position.timestamp === selectedPositionTimestamp) || null
    : null;
  return selectedPosition;
};

export const useGetLatestVesselPositionQuery = (imo: number | null) =>
  useGetVesselPositionsQuery({ imo: imo as number, mode: 'latest' }, { skip: !imo });

export const useGetVesselPositionsForSelectedDatesQuery = (imo: number | null) => {
  const { startDate, endDate } = useAppSelector((state) => state.vesselMapDateSelect);
  const differenceInDays = dayjs(endDate).diff(dayjs(startDate), 'day');

  let downsample = 'auto';
  if (differenceInDays > 2 && differenceInDays <= 7) downsample = '2m';
  if (differenceInDays > 7 && differenceInDays <= 182) downsample = '1h';
  if (differenceInDays > 182 && differenceInDays <= 365) downsample = '2h';
  if (differenceInDays > 365) downsample = '1d';

  return useGetVesselPositionsQuery(
    {
      imo: imo as number,
      from: startDate,
      to: endDate,
      mode: 'all',
      downsample,
      limit: 5000,
      extend: 'refpoint',
      fields:
        'ports.last_port,ports.current_port,ports.next_port,positions.status_desc,positions.source,positions.timestamp,positions.latitude,positions.longitude,positions.speed,positions.heading,positions.course,positions.rate_of_turn,positions.voyage.draught,positions.voyage.eta,positions.voyage.destination',
    },
    { skip: !imo || !startDate || !endDate }
  );
};

export const useTriggerCallbackWhenSelectedDatesChange = (callback: () => void) => {
  const { startDate, endDate } = useAppSelector((state) => state.vesselMapDateSelect);
  useEffect(() => {
    callback();
  }, [startDate, endDate, callback]);
};

export const useGetLatestPortsInformation = (
  last_port?: Port | undefined,
  next_port?: Port | undefined,
  current_port?: Port | undefined
) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.vesselDetails);
  const nextPortQuery = useGetPlacesQuery({ unlocode: `eq.${next_port?.unlocode}` }, { skip: !next_port });
  const currentPortQuery = useGetPlacesQuery({ unlocode: `eq.${current_port?.unlocode}` }, { skip: !current_port });
  const lastPortQuery = useGetPlacesQuery({ unlocode: `eq.${last_port?.unlocode}` }, { skip: !last_port });

  useEffect(() => {
    if (
      nextPortQuery.isLoading ||
      lastPortQuery.isLoading ||
      currentPortQuery.isLoading ||
      (!nextPortQuery.isUninitialized && !nextPortQuery.isSuccess) ||
      (!lastPortQuery.isUninitialized && !lastPortQuery.isSuccess) ||
      (!currentPortQuery.isUninitialized && !currentPortQuery.isSuccess)
    ) {
      return;
    }

    dispatch(
      setVesselDetailsState({
        ...state,
        nextPort: { ...nextPortQuery?.currentData?.[0], destinationType: 'Next port' },
        currentPort: { ...currentPortQuery?.currentData?.[0], destinationType: 'Current port' },
        lastPort: { ...lastPortQuery?.currentData?.[0], destinationType: 'Last port' },
      })
    );
  }, [dispatch, nextPortQuery, lastPortQuery, currentPortQuery, last_port, current_port, next_port]);
};
