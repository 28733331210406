import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { createAsyncActionErrorToast, createRequestErrorToast } from './utils';

export const displayErrorForRejectedThunkMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (!isRejectedWithValue(action)) {
    return next(action);
  }

  const url = (action.meta as { baseQueryMeta?: { request?: { url?: string } } })?.baseQueryMeta?.request?.url;
  if (url) {
    const message = (action.payload as { error?: string })?.error || 'Unknown';
    createRequestErrorToast(url, message);
    return next(action);
  }

  const message = JSON.stringify(action.payload);
  createAsyncActionErrorToast(message);

  return next(action);
};
