import { Toaster } from 'react-hot-toast';

import { Banner } from './features/banner/Banner';
import { Footer } from './features/footer/Footer';
import { Header } from './features/header/Header';
import { ReportProblemForm } from './features/report-problem/ReportProblemForm';
import { ContactForm } from './features/contact/ContactForm';
import { AppRoutes } from './features/routes/AppRoutes';

export const App = () => (
  <div className="flex flex-col h-screen">
    <Header />
    <Banner />
    <main className="flex-1 bg-primary lg:overflow-auto" role="main">
      <AppRoutes />
    </main>
    <Footer />
    <ReportProblemForm />
    <ContactForm />
    <Toaster />
  </div>
);
