import dayjs from 'dayjs';
import { Cookies } from 'react-cookie';

import { SinglePosition } from '../../common/types';
import { Shipdata, VesselCompanies } from '../../store/apis/ihs-vessel-data-api';
import { Equipment, Partner } from '../../store/apis/installation-api';

export type GroupedVesselCompany = {
  owcode: VesselCompanies['owcode'] | null;
  country_code: VesselCompanies['country_code'] | null;
  fullcompanyname: VesselCompanies['fullcompanyname'] | null;
  companies: VesselCompanies[];
};

export const groupVesselCompanies = (companies: VesselCompanies[]) => {
  let owcodeNames = companies.map((e) => e.owcode);
  owcodeNames = [...new Set(owcodeNames)];

  const groupedVesselCompanies: GroupedVesselCompany[] = owcodeNames.map((owcode) => {
    const filteredCompanies = companies.filter((e) => e.owcode === owcode);

    return {
      owcode,
      fullcompanyname: filteredCompanies?.length ? filteredCompanies[0].fullcompanyname : null,
      country_code: filteredCompanies?.length ? filteredCompanies[0].country_code : null,
      companies: filteredCompanies,
    };
  });

  return groupedVesselCompanies;
};

export const getCompanyRoleNames = (vesselGroup: GroupedVesselCompany) => {
  return vesselGroup.companies.map((c) => c.role_name).join(', ');
};

export const getInstallationPartnerRoleNames = (partner: Partner) => {
  return partner.role?.map((r) => r.name).join(', ');
};

export type EquipmentCategory = {
  name: string;
  equipmentGroup: EquipmentGroup[];
};

export type EquipmentGroup = {
  amount: number;
  equipment: Equipment;
};

export const groupEquipment = (equipment: Equipment[]): EquipmentCategory[] => {
  const filteredEquipment = equipment.filter(
    (equipment) => !equipment.productReferenceType?.code?.toUpperCase().startsWith('Z_')
  );

  // Group by category code
  const categoryNames: string[] = filteredEquipment.map((e) => e?.category?.name as string).filter((name) => !!name);
  const uniqueCategoryNames = [...new Set(categoryNames)];

  const categories: EquipmentCategory[] = uniqueCategoryNames.map((categoryName) => {
    const equipment = filteredEquipment.filter((e) => e?.category?.name === categoryName);

    let equipmentGroup: EquipmentGroup[] = [];
    equipment.forEach((e) => {
      if (!equipmentGroup.find((eq) => eq.equipment?.productReferenceType?.code === e?.productReferenceType?.code)) {
        equipmentGroup.push({
          amount: equipment.filter((eq) => eq?.productReferenceType?.code === e?.productReferenceType?.code).length,
          equipment: e,
        });
      }
    });

    return {
      name: categoryName,
      equipmentGroup,
    };
  });

  return categories;
};

export const getLastPositionSourceName = ({ source }: SinglePosition) => {
  let sourceText = 'AIS';
  switch (source?.toUpperCase()) {
    case 'SAT':
      sourceText = 'Satellite AIS';
      break;
    case 'TER':
      sourceText = 'Terrestrial AIS';
      break;
    case 'WAN':
      sourceText = 'Wärtsilä land-based station';
      break;
    case 'FOS':
      sourceText = 'FOS-collected AIS';
      break;
    case 'SSA':
      sourceText = 'SSAS';
      break;
    case 'OWN':
      sourceText = 'FOS';
      break;
  }
  return sourceText;
};

export const getSpeed = ({ speed }: SinglePosition) => {
  const notApplicable = 'N/A';

  if (speed === undefined || speed === null) {
    return notApplicable;
  }

  return `${speed.toFixed(1)} kn`;
};

export const getHeading = ({ heading }: SinglePosition) => {
  const notApplicable = 'N/A';

  if (!heading) {
    return notApplicable;
  }

  if (heading >= 0) {
    return `${heading.toFixed(0)}°`;
  }

  return notApplicable;
};

export const getDraught = ({ voyage }: SinglePosition) => {
  const draught = voyage?.draught;
  const notApplicable = 'N/A';

  if (!draught) {
    return notApplicable;
  }

  if (draught >= 0) {
    return `${draught.toFixed(1)} m`;
  }

  return notApplicable;
};

export const getCourse = ({ course }: SinglePosition) => {
  const notApplicable = 'N/A';

  if (course === undefined || course === null) {
    return notApplicable;
  }

  return `${course.toFixed(0)}°`;
};

export const getRateOfTurn = ({ rate_of_turn }: SinglePosition) => {
  const notApplicable = 'N/A';

  if (rate_of_turn === undefined || rate_of_turn === null) {
    return notApplicable;
  }

  return `${rate_of_turn?.toFixed(0)}°/min`;
};

export const getReceivedColorClass = ({ timestamp }: SinglePosition) => {
  if (!timestamp) {
    return '';
  }
  const hoursDiff = dayjs.duration(dayjs.utc().diff(dayjs.utc(timestamp))).asHours();

  if (hoursDiff > 24) {
    return 'text-red-600';
  }

  if (hoursDiff > 6) {
    return 'text-yellow-500';
  }

  return '';
};

// Add 1 hour time around the event in order to show some position markers before and after the event occured
// it gives a better overview of what has happened.
export const getPositionDatetimesFromEventDatetimes = (eventStartDatetime: string, eventEndDatetime?: string) => ({
  startDate: dayjs(eventStartDatetime).utc(true).subtract(1, 'hours').toISOString(),
  endDate: eventEndDatetime
    ? dayjs(eventEndDatetime).utc(true).add(1, 'hours').toISOString()
    : dayjs().utc().toISOString(), // Ongoing event, use current time
});

export const setCookie = (imo: string = '', name: string = '', shiptype: string = '', yearofbuild: string = '') => {
  if (!imo || !name) return;
  const cookie = new Cookies();

  const previous_recent_visits = cookie.get('recently_visited');

  const newVisit = {
    shipname: name,
    lrimoshipno: imo,
    shiptypelevel4: shiptype,
    yearofbuild: yearofbuild,
  };

  if (previous_recent_visits === undefined) {
    cookie.set('recently_visited', [newVisit], { path: '/', maxAge: 2592000 });
    return;
  }
  const listTakeAwayNew = previous_recent_visits.filter((visit: Shipdata) => visit.lrimoshipno !== imo);
  if (listTakeAwayNew.length < 3) {
    cookie.set('recently_visited', [newVisit].concat(listTakeAwayNew), { path: '/', maxAge: 2592000 });
  } else {
    listTakeAwayNew.pop();
    cookie.set('recently_visited', [newVisit].concat(listTakeAwayNew), { path: '/', maxAge: 2592000 });
  }
};
