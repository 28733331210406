import React from 'react';

import { ChevronDown } from '../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../common/components/icons/ChevronUp';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { emissionLayerApi } from './hooks';
import { toggleLegendIsOpen } from './slice';

type Props = {
  className?: string;
};

export const LayersLegend: React.FC<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { emissionLayer, legendIsOpen, aisQualityLayer } = useAppSelector((state) => state.mapLayers);

  const { config } = useAppSelector((state) => state.common);
  const legendQuery = emissionLayerApi.useGetLegendOptionsQuery(
    config?.VERSION ? `?version=${config?.VERSION}` : undefined
  );

  if (!emissionLayer.enabled && !aisQualityLayer.enabled) {
    return null;
  }

  return (
    <div className={`LayersLegend bg-primary/90 text-white p-2 ${className ? className : ''}`}>
      <span className="flex items-center justify-between cursor-pointer" onClick={() => dispatch(toggleLegendIsOpen())}>
        <span className="mr-2">Legends</span> {legendIsOpen ? <ChevronDown /> : <ChevronUp />}
      </span>
      {legendIsOpen && (
        <>
          {emissionLayer.enabled && (
            <div className="flex flex-col border-t border-secondary mt-2 pt-2">
              <div className="flex justify-between">
                <h1 className="mr-2">Emission</h1>
                <span className="text-gray-400">t CO₂ / km² / day</span>
              </div>
              <div className="flex flex-col">
                {legendQuery.data?.map((legend) => (
                  <div className="flex items-center justify-between hover:bg-secondary">
                    <div className={`p-2`} style={{ backgroundColor: legend.color }} />
                    <p>{legend.interval}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {aisQualityLayer.enabled && (
            <div className="flex flex-col border-t border-secondary mt-2 pt-2">
              <div className="flex justify-between">
                <h1 className="mr-2">AIS Data Quality</h1>
                <span className="text-gray-400">%</span>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center justify-between hover:bg-secondary">
                  <div className={`p-2`} style={{ backgroundColor: 'rgb(150, 0, 0)' }} />
                  <p>0-59</p>
                </div>
                <div className="flex items-center justify-between hover:bg-secondary">
                  <div className={`p-2`} style={{ backgroundColor: 'rgb(114, 14, 50)' }} />
                  <p>60-79</p>
                </div>
                <div className="flex items-center justify-between hover:bg-secondary">
                  <div className={`p-2`} style={{ backgroundColor: 'rgb(38, 42, 150)' }} />
                  <p>80-99</p>
                </div>

                <div className="flex items-center justify-between hover:bg-secondary">
                  <div className={`p-2`} style={{ backgroundColor: 'rgb(0, 56, 200)' }} />
                  <p>100</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
