import dayjs from 'dayjs';
import React from 'react';
import { getPortCallsFlag, getPortDescription, getPortCallDurationText } from '../../utils';
import { ListItem } from '../ListItem';
import { PortCalls } from '../../../../store/apis/ais-api';
import { useAppSelector } from '../../../../common/hooks';

type Props = {
  portCall: PortCalls;
  selected: boolean;
};

export const PortCallItem: React.FC<Props> = ({ portCall, selected }) => {
  const dateState = useAppSelector((state) => state.vesselMapDateSelect);

  return (
    <ListItem
      key={portCall.arrival_time}
      item={portCall}
      type="port-calls"
      className={selected ? 'bg-secondary/60' : ''}
      date={`${dayjs.utc(portCall.arrival_time).format('YYYY-MM-DD HH:mm')} UTC`}
    >
      <>
        <div className="py-1">
          <span className={getPortCallsFlag(portCall)}></span>
          {getPortDescription(portCall) && (
            <a className="link font-medium hover:text-accent no-underline" href={`/port/${portCall.port_id}/`}>
              {getPortDescription(portCall)}
            </a>
          )}
        </div>
        {getPortCallDurationText(portCall, dateState.endDate) && (
          <div className="flex items-center">
            <span className="text-sm">{getPortCallDurationText(portCall, dateState.endDate)}</span>
          </div>
        )}
      </>
    </ListItem>
  );
};
