import { iprApi as api } from '../base/base-apis';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addPhoto: build.mutation<AddPhotoApiResponse, AddPhotoApiArg>({
      query: (queryArg) => ({ url: `/photos`, method: 'POST', body: queryArg.photo }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export type AddPhotoApiResponse = {
  detections: Vessel[];
  photo_id: number;
}; /** status 200 successful operation */

export type AddPhotoApiArg = {
  /** Vessel photo and metadata */ photo: Photo;
};

export type Vessel = {
  mmsi?: number;
  imo?: number;
  vessel_name?: string;
  bbox?: number[];
  confidence_score?: number;
};

export type Photo = {
  file?: {
    name?: string;
    content?: string | ArrayBuffer | null;
  };
  location?: {
    latitude?: number;
    longitude?: number;
    direction?: number;
  };
  timestamp?: string;
  client?: string;
};

export const { useAddPhotoMutation } = injectedRtkApi;
