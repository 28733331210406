import dayjs from 'dayjs';
import { Feature, Geometry } from 'geojson';
import { LayerProps, SourceProps } from 'react-map-gl';

import { SinglePosition } from '../../common/types';
import { customColors } from '../../common/utils';
import { Vessel, VesselPosition } from '../../store/apis/ais-api';
import { Anomalies } from '../../store/apis/vessel-event-api';
import { VesselMapState } from './slice';

type PopupData = {
  heading?: number;
  draught?: number;
  time?: string;
  speed?: number;
};
export const getPopupContentForLocation = ({ heading, draught, time, speed }: PopupData) => {
  const headingText = heading
    ? `Heading:
    <strong>
    ${heading}°
     </strong> `
    : '';

  const draughtText = draught
    ? `Draught:
    <strong>
    ${draught}
     m</strong> `
    : '';

  const output = `<div class="font-light text-sm">
      <div class="text-gray-400">
      ${dayjs.utc(time).format('YYYY-MM-DD HH:mm:ss')}
       UTC
      </div>
      <div class="text-gray-400">
      ${dayjs.utc(time).fromNow()}
      </div>
      <div>
      Speed:
      <strong>
      ${speed} kn
      </strong>
      </div>
      <div>
      ${headingText}
      </div>
      <div>
      ${draughtText}
      </div></div>`;
  return output;
};

export const isAnomalyPosition = (positionTimestamp: string, anomalies: Anomalies[]) =>
  anomalies.some((anomaly) => {
    const anomalyOngoing = anomaly.start_datetime && !anomaly.end_datetime;
    if (anomalyOngoing) {
      // Anomaly's datetime string is missing the timezone, we convert it to UTC but keep time as is.
      return dayjs(positionTimestamp).isAfter(dayjs(anomaly.start_datetime).utc(true));
    }

    return dayjs(positionTimestamp).isBetween(
      dayjs(anomaly.start_datetime as string)
        .utc(true)
        .toISOString(),
      dayjs(anomaly.end_datetime as string)
        .utc(true)
        .toISOString()
    );
  });

export const getAnomalyCoodinatesFromPositions = (positions: any[], anomalies: Anomalies[]) => {
  const anomalyVesselRouteCoordinates: number[][][] = [];
  let currentCoordinatesSet: number[][] = [];

  const addCoordinatesSetAndReset = () => {
    anomalyVesselRouteCoordinates.push([...currentCoordinatesSet]);
    currentCoordinatesSet = [];
  };

  positions.forEach((position, index) => {
    if (isAnomalyPosition(position.timestamp, anomalies)) {
      currentCoordinatesSet.push([position.longitude, position.latitude]);

      const isLastCoordinate = index === positions.length - 1;
      if (isLastCoordinate) {
        addCoordinatesSetAndReset();
      }
      return;
    }

    if (!currentCoordinatesSet.length) {
      return;
    }

    addCoordinatesSetAndReset();
  });

  return anomalyVesselRouteCoordinates;
};

const splitcoordinatesPath = (coordinates: number[][]) => {
  const list = [];
  let lastSplit = 0;
  for (const index in coordinates) {
    if (coordinates[Number(index) + 1] && coordinates[index][0] > 0 != coordinates[Number(index) + 1]?.[0] > 0) {
      list.push(coordinates.slice(lastSplit, Number(index) + 1));
      lastSplit = Number(index) + 1;
    }
  }
  list.push(coordinates.slice(lastSplit));
  return list;
};

export const getVesselOutlineSourceProps = (coordinates: number[][]): SourceProps => {
  const splitCoordinates = splitcoordinatesPath(coordinates);
  const collection: Feature<Geometry>[] = splitCoordinates.map((section) => ({
    type: 'Feature',
    properties: {},
    geometry: { type: 'LineString', coordinates: section },
  }));

  return {
    id: 'vessel-outline-source',
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: collection,
    },
  };
};

export const vesselOutlineLayerProps: LayerProps = {
  id: 'vessel-outline-layer',
  type: 'fill',
  minzoom: 15,
  paint: {
    'fill-color': '#000000',
  },
};

export const getVesselRoutesSourceProps = (coordinates: number[][]): SourceProps => {
  const splitCoordinates = splitcoordinatesPath(coordinates);
  const collection: Feature<Geometry>[] = splitCoordinates.map((section) => ({
    type: 'Feature',
    properties: {},
    geometry: { type: 'LineString', coordinates: section },
  }));

  return {
    id: 'vessel-routes-source',
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: collection,
    },
  };
};

export const vesselRoutesLayerProps: LayerProps = {
  id: 'vessel-routes-layer',
  type: 'line',
  paint: {
    'line-color': customColors.mapOrange,
    'line-width': 3,
  },
};

export const getAnomalyVesselRoutesSourceProps = (id: string, coordinates: number[][]): SourceProps => {
  const splitCoordinates = splitcoordinatesPath(coordinates);
  const collection: Feature<Geometry>[] = splitCoordinates.map((section) => ({
    type: 'Feature',
    properties: {},
    geometry: { type: 'LineString', coordinates: section },
  }));

  return {
    id,
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: collection,
    },
  };
};

export const getAnomalyVesselRoutesLayerProps = (id: string): LayerProps => ({
  id,
  type: 'line',
  paint: {
    'line-color': '#FF0000',
    'line-width': 3,
  },
});

// Configure limits for when to apply calculated heading on position markers
const calculateHeadingConf = {
  maxMinutesDiff: 30,
  maxAngleDiff: 20,
};

export const getCalculatedHeadingForPosition = (
  position: SinglePosition,
  positions: SinglePosition[]
): number | null => {
  const currentPositionIndex = positions.findIndex((p) => p.timestamp === position.timestamp);

  // Note, positions are sorted by newest first
  // Get all positions before current position and find the first one with a heading
  const nextPositionWithHeading = positions
    .slice(0, currentPositionIndex)
    .reverse()
    .find((p) => !isNaN(p.heading as number));

  // Get all positions after current position and find the first one with a heading
  const prevPositionWithHeading = positions.slice(currentPositionIndex).find((p) => !isNaN(p.heading as number));

  if (!nextPositionWithHeading?.heading || !prevPositionWithHeading?.heading) {
    return null;
  }

  const minutesSincePrev = dayjs
    .duration(dayjs(prevPositionWithHeading.timestamp).diff(dayjs(position.timestamp)))
    .asMinutes();
  const minutesSinceNext = dayjs
    .duration(dayjs(position.timestamp).diff(dayjs(nextPositionWithHeading.timestamp)))
    .asMinutes();

  const { maxMinutesDiff, maxAngleDiff } = calculateHeadingConf;

  if (minutesSincePrev > maxMinutesDiff || minutesSinceNext > maxMinutesDiff) {
    return null;
  }

  const rotationDiff = getAngleDifference(nextPositionWithHeading.heading, prevPositionWithHeading.heading);

  if (rotationDiff > maxAngleDiff) {
    return null;
  }

  return (prevPositionWithHeading.heading + nextPositionWithHeading.heading) / 2;
};

const getAngleDifference = (alpha: number, beta: number) => {
  const phi = Math.abs(beta - alpha) % 360; // This is either the distance or 360 - distance
  const distance = phi > 180 ? 360 - phi : phi;
  return distance;
};

export const getMinMaxCoordinates = (vesselPosition: VesselPosition) => {
  const coordinates = {
    minLat: 0,
    minLon: 0,
    maxLat: 0,
    maxLon: 0,
  };

  vesselPosition.positions?.forEach((position, index) => {
    if (index === 0) {
      coordinates.minLat = position.latitude as number;
      coordinates.minLon = position.longitude as number;
      coordinates.maxLat = position.latitude as number;
      coordinates.maxLon = position.longitude as number;
      return;
    }

    coordinates.minLat = Math.min(position.latitude as number, coordinates.minLat);
    coordinates.minLon = Math.min(position.longitude as number, coordinates.minLon);
    coordinates.maxLat = Math.max(position.latitude as number, coordinates.maxLat);
    coordinates.maxLon = Math.max(position.longitude as number, coordinates.maxLon);
  });

  return coordinates;
};

export const calculateEarthRadius = (latitude: number) => {
  const equatorRadius = 6378000;
  const polarRadius = 6357000;
  const term1 = Math.pow(Math.pow(equatorRadius, 2) * Math.cos(latitude), 2);
  const term2 = Math.pow(Math.pow(polarRadius, 2) * Math.sin(latitude), 2);
  const term3 = Math.pow(equatorRadius * Math.cos(latitude), 2);
  const term4 = Math.pow(polarRadius * Math.sin(latitude), 2);
  return Math.sqrt((term1 + term2) / (term3 + term4));
};

export const calculateDistance = (latitude1: number, longitude1: number, latitude2: number, longitude2: number) => {
  const toRadian = (n: number) => (n * Math.PI) / 180;

  let lat2 = latitude2;
  let lon2 = longitude2;
  let lat1 = latitude1;
  let lon1 = longitude1;

  let R = calculateEarthRadius(latitude1);
  let x1 = lat2 - lat1;
  let dLat = toRadian(x1);
  let x2 = lon2 - lon1;
  let dLon = toRadian(x2);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

export const getAngles = function (width: number, length: number, lengthOffset: number) {
  const alfa = Math.atan(width / (length - lengthOffset / 2));
  const gamma = Math.atan(width / (length + lengthOffset / 2));
  const beta = Math.PI - alfa - gamma;

  return { alfa: alfa, beta: beta, gamma: gamma };
};

export const getPolygonFromMeasures = function (
  lat: number,
  lon: number,
  length: number,
  width: number,
  bowLength: number,
  rotation: number,
  lengthOffset: number,
  widthOffset: number
) {
  // There's still a lot of magic numbers in this function

  const rot = (-rotation * Math.PI) / 180;
  let points = [];

  const latConv = calculateDistance(lat, lon, lat + 0.2, lon) * 10;
  const lngConv = calculateDistance(lat, lon, lat, lon + 0.2) * 10;

  const angles = getAngles(width, length, lengthOffset);

  let angle = 0;
  // Go through all the corners of the vessel outline and determine the side lengths. Starting from the lower left corner
  for (let i = 1; i <= 5; i += 1) {
    let r1a;
    let r2a;
    if (i === 3) {
      r1a = length + bowLength - lengthOffset * 2;
      r2a = 0;
    } else if (i === 1 || i === 5) {
      r1a = length + lengthOffset * 2;
      r2a = 4 * (width + widthOffset);
    } else {
      r1a = length - lengthOffset * 2;
      r2a = 4 * (width - widthOffset);
    }
    if (i === 2 || i === 5) {
      angle += angles.beta;
    } else if (i === 3 || i === 4) {
      angle += angles.gamma;
    } else {
      angle += angles.alfa;
    }

    const y = r1a * Math.cos(angle);
    const x = r2a * Math.sin(angle);
    const lonNew = (x * Math.cos(rot) - y * Math.sin(rot)) / lngConv;
    const latNew = (y * Math.cos(rot) + x * Math.sin(rot)) / latConv;

    points.push([lat + latNew, lon + lonNew]);
  }
  return points;
};

export const getVesselOutlineCoordinates = (
  refpoint: Vessel['refpoint'],
  position: SinglePosition,
  heading: number | null
) => {
  if (!refpoint) {
    return null;
  }

  const { a, b, c, d } = refpoint;
  if (typeof a !== 'number' || typeof b !== 'number' || typeof c !== 'number' || typeof d !== 'number') {
    return null;
  }

  if (typeof heading !== 'number') {
    return null;
  }

  const vesselLength = a + b;
  const vesselWidth = c + d;
  const widthOffset = (c + d) / 2 - d;
  const lengthOffset = (a + b) / 2 - a;

  const bowLength = vesselLength / 8;
  const polygon = getPolygonFromMeasures(
    position.latitude as number,
    position.longitude as number,
    vesselLength,
    vesselWidth,
    bowLength,
    heading + 180,
    lengthOffset,
    widthOffset
  );

  return [
    [polygon[0][1], polygon[0][0]],
    [polygon[1][1], polygon[1][0]],
    [polygon[2][1], polygon[2][0]],
    [polygon[3][1], polygon[3][0]],
    [polygon[4][1], polygon[4][0]],
    // If we don't duplicate the coords, the shape will be distorted on higher zoom levels.
    [polygon[0][1], polygon[0][0]],
    [polygon[1][1], polygon[1][0]],
    [polygon[2][1], polygon[2][0]],
    [polygon[3][1], polygon[3][0]],
    [polygon[4][1], polygon[4][0]],
  ];
};

type VesselMapUrlParams = {
  outline?: 'true';
  markers?: 'source';
};

export const getStateFromUrl = (initialState: VesselMapState): VesselMapState => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: Partial<VesselMapUrlParams> = Object.fromEntries(urlSearchParams.entries());

  return { ...initialState, showVesselOutline: params.outline === 'true', sourceMarkers: params.markers === 'source' };
};

export const getPositionMarkerIcon = (isAnomalyMarker: boolean, hasHeading: boolean, hasCalculatedHeading: boolean) => {
  if (hasHeading) {
    return isAnomalyMarker
      ? '/assets/images/positions/position-direction-anomaly.svg'
      : '/assets/images/positions/position-direction.svg';
  } else if (hasCalculatedHeading) {
    return isAnomalyMarker
      ? '/assets/images/positions/position-direction-anomaly-gray.svg'
      : '/assets/images/positions/position-direction-gray.svg';
  } else {
    return isAnomalyMarker
      ? '/assets/images/positions/position-no-direction-anomaly.svg'
      : '/assets/images/positions/position-no-direction.svg';
  }
};

export const getPositionMarkerIconWithSource = (source: string | undefined, hasHeading: boolean) => {
  if (!source) {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-other.svg'
      : '/assets/images/special_positions/position-no-direction-other.svg';
  }
  if (source.toUpperCase() === 'FOS') {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-pink.svg'
      : '/assets/images/special_positions/position-no-direction-pink.svg';
  }
  if (source.toUpperCase() === 'SAT') {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-purple.svg'
      : '/assets/images/special_positions/position-no-direction-purple.svg';
  }
  if (source.toUpperCase() === 'TER') {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-cyan.svg'
      : '/assets/images/special_positions/position-no-direction-cyan.svg';
  }
  if (source.toUpperCase() === 'WAN') {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-green.svg'
      : '/assets/images/special_positions/position-no-direction-green.svg';
  }
  if (source.toUpperCase() === 'SSA') {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-blue.svg'
      : '/assets/images/special_positions/position-no-direction-blue.svg';
  }
  if (source.toUpperCase() === 'OWN') {
    return hasHeading
      ? '/assets/images/special_positions/position-direction-yellow.svg'
      : '/assets/images/special_positions/position-no-direction-yellow.svg';
  }
  return hasHeading
    ? '/assets/images/special_positions/position-direction-other.svg'
    : '/assets/images/special_positions/position-no-direction-other.svg';
};
